import "../../core/src/components/doc-properties/info-modal/info-modal.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/info-modal/info-modal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41T3YrcIBS+n6c4LBRmL1xMJpvduhT6GIVSihNNIhgVdTIzLfPuJUYzid0uvYsnOd+fX55+Fqp7PrgXDL93AAO9oLNgvidQvWJzeZtmQqXZoY4zQxkTqiNQYnMBPI2oFJ1CjsuWgPPU+rBLbScU8toQKMqwfNs9Jc4icDLhjKRXAq3kATySFRh/mo7TGDFheeOFVgQaLU+DWqEftfd6mLVsxWV8ZeBrtfLIiV+cwEjtHiHatkJxFOY9Kh/f0kdnLrreE6gxTkH0cVSVGRfeMB3ed5Zbsfo8jTtqCNQZYDynMOo8vGq5sKSpobLZFxiPPSAopzQetxKhrDKQ5wASOSKjt1S5VtuBzI+Sev5tj6oAt9qt17shoCQkHIx2YnZJj07Lk+eb7Zco/96PdH1HbRm3y63mkl//L9p7S+5CLJfUi3Er5HPAW7c3QC0V/rAubggRN1pqm70fS+T5xSPHG60Ytdf8MqrZbgrtEOPfNK+am3cvyG33nVFPkbHacOuvqNFSUuM4+/Lg7Yk//IDM2ZKU0ip37sYu/yWKKOMflkSj1cbSGo/0euR2Qf0IwlgxJICvA2eCgrFC+bCYOfjLA8Btd/sD2XG+U7oEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addPropertyButton = '_1ng53s79';
export var container = '_1ng53s70';
export var hiddenInput = '_1ng53s76';
export var rowNameContainer = '_1ng53s73';
export var scrollBar = '_1ng53s75';
export var tableBodyRoot = '_1ng53s78';
export var timeRow = '_1ng53s77';
export var titleContainer = '_1ng53s71';
export var titleStyle = '_1ng53s72';
export var viewport = '_1ng53s74';