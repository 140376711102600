import "../../core/src/components/doc-properties/table.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/table.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VWW27bMBD8zymIAAVsoHRlxXEdpgGKXqAHKPqxFlcyW4okSEq2WvjuhahHaFtukvbLFsndmR3OrrRoNibdyJT8viGE0u4pYWS5TszhMVpbMlKDnVEKeS4U0lwrT105b89w4YyEhpFcYgjaC+53jCyT5F37+KNyXuQNzbTyqDwjGSqPtt0KaXIohWwmAUC5fnv+eHO8WXRk7gLdC9j2l3JhMfNCK0YyLatStTsFGEY2ZopcCQfaL53gIxde224rVGmAc6EKRpLJg05wpP2Z9yRdmUPMeDXNeIei2HlG7nq1R4xV93yhnDOQId2i3yOGykCKQlHhsXSxrpmW2p5VVKfU48FTh5lWHGwzH29g3/O4T5KI9P11mRlZTvILV4CKBw6VdS0Jo8XJdTvxCxlJQ8kj1jpgvZb2GPYxhEVXs+6Em8DeasvRUgtcVK5X+DkP2+kabci2hexnYXWlOL3CR0KDlkbnQvDXGq2EE3qb/zFqJNaV1htxHt6Kc2Ib58H6c/RBQY45VNI/Tsg8wkOAH8ycRGp7bRhJFvfmQJyWgl+RUqi2fb50MV3ofLJXbSFUoEhio27/XeYxR/bWHEY70a1ZlOBFjVE2/rpsVu8jYTu10o7WNw4eqLHaoPVth0kJxiF/uvW2wtvvZBpJaRXzwLD7spPeMjEuu20cY6vIucNMWSXJKPn6dAQPL5mp1pwafp1Po+JcXZwXuBwmwHQ9ItNqepRgPwKGnH9LYKwoz8LzEKQNZMI37MSevM88fXIZneyQvQU1eCv8z7UtSbJIHUFwSIWiuoqFKDqzXHpkzNZmaO3mwePsIeFYxNx3Z3Pv2sTb74TH+dlbMwOZza6/OskHkk738oE+v/7qXbvWqpRLvacNI1B5HVEUbAdudtYUNcgKv3eLWBrfDIXPp5vic2slEAotqQXujbaezAIv8umJrFeJOXSRi/iz6IWPoIML5R2fw+76sKhRBlceWxYlcgHEWKF8jLbqw85on6SGV5zZdoK49q6fbrVB1TthGKB0q73XJSN36cBqDMbrAMc/rSE3Gy0KAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionContainer = 'y8p28ld';
export var backLinksMenu = 'y8p28lh';
export var backlinksList = 'y8p28l8';
export var collapsedIcon = 'y8p28lg';
export var fontSize = 'var(--y8p28l1)';
export var pageInfoDimmed = 'y8p28l6';
export var propertyActionButton = 'y8p28le';
export var propertyConfigButton = 'y8p28lf';
export var propertyRootHideEmpty = 'y8p28li';
export var root = 'y8p28l2';
export var rootCentered = 'y8p28l3';
export var tableBodyRoot = 'y8p28lb';
export var tableBodySortable = 'y8p28lc';
export var tableHeader = 'y8p28l4';
export var tableHeaderBacklinksHint = 'y8p28l7';
export var tableHeaderCollapseButtonWrapper = 'y8p28l5';
export var tableHeaderDivider = 'y8p28la';
export var tableHeaderTimestamp = 'y8p28l9';