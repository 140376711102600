import "../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4VT227bMAx971dwAQYsDyrsXLpWRb9kKAbGom2tiiRIdJxs6L8PkpPGTpPtkRedc3hI3f8sl53H3fYB/twBbFxQFERApbso4dHvn8/ZXituJZSTZOSDIQnRGa2G9F7EFpXrJewwfBMC61pbOiZFOc9dWL01wXVWicoZF069JzXFfERxtWORO66WEsX73f0p/J4n86iUto2E8iHpH9Ufc13p6A0eJNSG8nxodGOFZtrGISkiY+BU+tVF1vVBVM4yWZYQPVYkNsQ9kZ2AP2XwlnTTsoTFavDu32QVWaYwgcEMc1zATRRvkowLFIAthkZbEQYNZXExP0LcNWP8sii+Po9Un+KrZq+mZm/GQEV6la1rQrqHMsVuR6E2KWy1UskuAKY9i3OBjNE+6phKfauZRDZYgnV9QJ9RnWXRHwWuisxk0pldWp0bo/5NEsr1kPqPH1Ue4aLp8mhUbrrqyDJfpndRs3ZWQiCDrHc0vXsJHNBGj4Esn489zWhpQkU/FDKK2jjklxmHjmavw0l/UOAmOtNxpmDnT3oBbsmvb8tfz+GL3noXGJOw0aNmbEymWX/yePLlh/x2/nk5i0HPMFmvuRW6cvYVzlxDKf+LlxlrNh9TDx9ZGKpZwjKv+f0vRJlDU8cEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = '_13upavmc';
export var actionButton = '_13upavmd';
export var actionTextColor = 'var(--_13upavm3)';
export var card = '_13upavm6';
export var cardBorderColor = 'var(--_13upavm2)';
export var cardColor = 'var(--_13upavm0)';
export var cardForeground = 'var(--_13upavm1)';
export var cardInner = '_13upavm7';
export var closeButton = '_13upavme';
export var closeIcon = '_13upavmf';
export var closeIconColor = 'var(--_13upavm5)';
export var headAlignWrapper = '_13upavm9';
export var header = '_13upavm8';
export var icon = '_13upavma';
export var iconColor = 'var(--_13upavm4)';
export var main = '_13upavmg';
export var title = '_13upavmb';