import "../../component/src/ui/tags/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/tags/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51U227bMAx9z1dwDwOaFSrsNO06Bd13DBiKQZHoWJ0sCRIdOxv274PluHZuxbKHJAhFHR4e6vDuR+5i9boI2XclSDCsPO2eKdT4Ar9nANIZFzhsRbhhTBSFtsi8ERJLZxQGls7nq9mf2d2AlKeLSkdvxI5DYbBdzSD9MqUDStLO8g65rmx30mhFJYc8yz52fzfCc8gXvj1AXZxHTcmPfqzQhC7SfZ9AT9DuE5oXSmm74ZB1ud5F3VMLaATpLXZRcp6DFEbesPu7B9/C7V6MIJRumXfeY2DCytIFVqLelDSHT8DyeXfbYEEc+psTPgnvMkzKmsMt5I++TThui6EwruFQaqXQHjSzfEea5UVp3prPM9/u9QZYC/lzE1xtFTs3+rIj8jZ0gErbfdMclscje0i8UulN6Ljnp3WT8pUIG205sDG0dkFh4GCdTWNwNRltcQwUzhKL+hdyyIcmu1AhKm12HLQtMWhajW94EjntkoKw0YuAlg574Hzy3P/XEo/XWiLN7qlva3ylXWT49Lq1o/pZ1gfHpyJqcgc0Pp+n8VpH0sWOSWcJLXGIXkhka6QGMdERRm8s04RV5CDREoZTZkdTOdAnxds4f8tp9rQfsmx1QVPCllhE6awSYXdO1qfUz9Awa0d3XHLzVLHFoNgo9gT7y7+NLLjmKoGG4vfL49oAsg6xU8E7PQD0NkhLoo57N09J9ku7cLKOqCZr+yobTwBFr6gXUtMu7cVpNZ7uwaXs/BCq5xZJED47j/blvez1mV0xOVbpOJm0cKHa+9UIwm83y7QjJ8l4rdvOvop3fIR9a/3qjjI4Y5gIKNhWY+NdoBf4CkpvD3mQWBuED7rqMkTaM38B0sn5EX4HAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_1osmj2r2';
export var menuItemList = '_1osmj2re';
export var menuItemListScrollable = '_1osmj2rc';
export var menuItemListScrollbar = '_1osmj2rd';
export var searchInput = '_1osmj2r5';
export var spacer = '_1osmj2rb';
export var tagEditIcon = '_1osmj2ra';
export var tagSelectorItem = '_1osmj2r9';
export var tagSelectorTagsScrollContainer = '_1osmj2r8';
export var tagsEditorRoot = '_1osmj2r1';
export var tagsEditorSelectedTags = '_1osmj2r4';
export var tagsEditorTagsSelector = '_1osmj2r6';
export var tagsEditorTagsSelectorHeader = '_1osmj2r7';
export var tagsInlineEditor = '_1osmj2r0';
export var tagsMenu = '_1osmj2r3';