import "../../core/src/components/affine/setting-modal/general-setting/billing/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/billing/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61U227bMAx9z1fwZUD7oMDOkjRTgf3KIFu0zcG6QJIbZ8P+fbCSrJbj1Gu3J0OieHjIc+j1t1zt/FH7HH6uACR524oTh6rF/nkF8cskOSwDGc2hNG2n9BARLdWaUUDlOZSoA7rhuhaWw2Zr++fVr9X6ir6J6EeSoeGQZ9mnJPp5vvb3zgeqTqw0OqAOHLwVJbICwxExcrBCStI1h3xjY0phnETHIbc9eNOShBfhHhgTVUUa2TnMStMa9/j6njkhqfMcDhPa20hs4MMhhwyyJLobNyW6YAZEJVxNmgVjb+H2ZzijA/P0A3lKLt43bB+JxcMRqW4Ch32WFn5aximEx8ck6RCTxvT+qCSFrtGZzjOPIZCuYa2FwpgQZzWpgM6Z1ymOany5mxKwD8xjabQU7jRS4M0uep/ii+XGY8pkfrthfm/wQmUb4cmPaJWd88Nja+js7BGL4r2rMr8T5XthFGnWXFp6uhj+zhberM41NKovP7p1Fw8VJgSjbl2OyyJ5dcfkAO3w6Nrl5tLlXeWsIzX204hG9ddeSUtm55IJt+2CgW6NnRD5CuLuYhROaDndh8S4I6j6n37SqGWC1kwnlB9mep/VZf9xXeg/6rK02DO6/AY6K+bi8QYAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var believerHeader = '_1m5swnsd';
export var believerPrice = '_1m5swnsh';
export var believerPriceCaption = '_1m5swnsi';
export var believerPriceWrapper = '_1m5swnsg';
export var believerSubtitle = '_1m5swnsf';
export var believerTitle = '_1m5swnse';
export var billingFrequency = '_1m5swns7';
export var billingHistorySkeleton = '_1m5swnsc';
export var currentPlan = '_1m5swns4';
export var currentPlanName = '_1m5swnsa';
export var history = '_1m5swns1';
export var historyContent = '_1m5swns2';
export var noInvoice = '_1m5swns9';
export var paymentMethod = '_1m5swns8';
export var planAction = '_1m5swns5';
export var planCard = '_1m5swns3';
export var planPrice = '_1m5swns6';
export var subscription = '_1m5swns0';
export var subscriptionSettingSkeleton = '_1m5swnsb';