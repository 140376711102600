import "../../core/src/components/affine/setting-modal/workspace-setting/properties/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/workspace-setting/properties/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVy27bMBC8+yv2UqA50JCdWHEZ9EuKHGhyJW1CkQRJxUqL/nsh0g/Zhg25aE8SudTszO5wNdfPsnRtAb9mAIqC0+KTQ6Wxf5lBejJFHmUkazhIq7vWDJEtqdhwWBTFl2H51oVI1SeT1kQ0kYNEE9G/zH7P5jnDYloGb7djPIrYhnyaoVFDSGiqzT6wTwPghFJkag6l66EYdlrhazJsY2O0LYdF6foRn+VdfCYnrYVLq+HddlGTQQ7GGhw2ohcmUAbeCPlee9sZBcV8GQBFwDG9H0pEwRqqG011E18T3eNHHD6E/8qYqCoyyI4BFlBao4QfmqGtfxiBPt7b5aTmKau5Xc6nBJ0ynlEjac2BCkBlTWSBfuIFxOoqRMQ+MuepHas6gTo5nvfbdMZ+oK+03XJoSClMqhLcMYBakwsUkq0bisiCEzK1beuFGzPMTemMES2q79F3+HqVtNNCYmO1Qn/ZifK21sseTlN7m/5zSnp2cxsc7MWzezfWD3SjdRwWrodgNakzp+UjF4rWCTxZqfZDWRej4LfbchUFsdE4VWwfxonF/ynlmd3XJ1bd/IvhkS7XOl+uKYzS+3bXrlWRGnaX7MO0Wrt+d/kAWjJsZ4llWZyolLmlt6g1bPUwMo4XirpwGBkXBbpSiat/j4NbH5cZce/W/TpVPDSezPuJhafa969H6h8dwtX+OAcAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var divider = 'l7c6pm7';
export var listHeader = 'l7c6pm1';
export var main = 'l7c6pm0';
export var metaList = 'l7c6pm3';
export var propertyDocCount = 'l7c6pm6';
export var propertyIcon = 'l7c6pm4';
export var propertyName = 'l7c6pm5';
export var propertyNameIconEditable = 'l7c6pmc';
export var propertyRequired = 'l7c6pm9';
export var propertyRow = 'l7c6pm2';
export var propertyRowNamePopupRow = 'l7c6pmb';
export var spacer = 'l7c6pm8';
export var subListHeader = 'l7c6pma';