import "../../component/src/ui/tags/tag.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/tags/tag.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227bMAx9z1fopUANTIHjpGurYF8y7EGxaJurIhmSnMuK/fsgyY4vcdMF20tgUyZ5eA5JZemej1Zn5H1BSK0tOtSKEQOSOzzAdkHIEYWrGFml6YN/rQDLyvXve1S0s62z+rRd/F4sQ9B1CCrQ1pKfGSkknLwDl1gqig72lpEclAPT+2yCjz6AKaQ+0hMjFQoBavshvCmekteMvAxxPIWYPj09Gn/qf7edSaCBPIbNtWz26gpj+Mw6btwl/mYY/+t8nTEa7fF8UPqwML6zWjZutrA9P9GJFs5wdXGVkqTLzJJ0ubYEuAWKiurG9UAzVnlmyRD2IOqBm0dKw1ma9F7Pk94YYnS6ZiT1TxIK1z6aiDudK0LXPEd3bk/n8Hv7judvpdGNEoxIVMANLQ0XCMo9vqYCyi/RteYGlCPpw5cWPC8KVEBDmTTXUhtaoJQgErJJH5KPqIglXrCt+u9ewklXRZZFHa+09kPQspjeEPpnYx0WZ5pr5UA5RmzNc6A7cEfwPX7JyiS3juYVShFV6uN7wVr/3uH1jlH7HAYhNRcCVclI2rVuIJONaXZwcrQ2uOfmHNlOgnraiI7+icfwaKDG63fBHaeFzhsL4pszDfwIFd0INc3bxeJx2rVy1OIvYARVBQbdAJlvpcb6pqyvJnUTTe2X3byNjNadJTBitUQx7dZxtZeDGZaG45y1JE+nJRDcLUNGQEqsLYYROVbogAbhGFE6rrSOg92UgxGsaN8nI6E39Ymssms+WmT3IulrWafDXZn/NbJrVkOAVTLfnxNpRzIGAaNe43yHjPrG2/llGT9N5ld8/h8bVHxOQUWfkukm7RewdZi/nUd7CJUMzmHuu0QQErU9NumwedaeYtvFC68yqN7a5DfE6LIVo5t7eG/f3zuN9WMGEnLnzQr6NOW/bbr+6DJ7k9HL7mMmb4z10tcax/9kyvaGeb+1IQYXlafyD79IhXCNCQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hoverMaxWidth = 'var(--t7wso0)';
export var innerBackdrop = 't7wso7';
export var innerContainer = 't7wso6';
export var root = 't7wso2';
export var showMoreTag = 't7wsod';
export var tag = 't7wso8';
export var tagColorVar = 'var(--t7wso1)';
export var tagIndicator = 't7wsoe';
export var tagInlineMode = 't7wsoa t7wso9';
export var tagInnerWrapper = 't7wso9';
export var tagLabel = 't7wsof';
export var tagLabelMode = 't7wsoc t7wso8';
export var tagListItemMode = 't7wsob t7wso8';
export var tagRemove = 't7wsog';
export var tagsContainer = 't7wso3';
export var tagsListContainer = 't7wso5 t7wso3';
export var tagsScrollContainer = 't7wso4 t7wso3';