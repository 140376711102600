import "../../component/src/ui/property/property.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/property/property.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W7W7jKBT9n6dAkUZqpVLZmdTtUI20b7APUPUHNtc2WwIIsJPsqu++MviLfDXtzEr7I7JNuIfDvfccuBdtmuQqQf8sEGLcakH3BJUCds8LhDZc4hp4VTuCvq+0H9PKcseVJMiAoI634EcpY1xWBK30DiV6558+4n1x36/xwgytKpoL+Ll0poHlq1+1aIxVhqDK0Pzy9BdGHcV+mMsqAmm55TkX3O0JqjljIC9DkRxKZSAQUNKBdAQtl8/zLORCFW/xlmlulWic33LMGyGnNEEPybfuXUDpCEq611wZBgYbynhjCeqzmNPirTKqkQwXSnQwLTU3GNOy5BJwu8IOdg5rQQuolWBgbv0ahkpbKrMh4VVQBzc407s7hB+Sb9Ocnm8oHkruVxYl96m9Q0rTgrv9NNSF9IM946Hi60B1y5mrZ19C4KKmsoIBfgT9IOW0dGD+Rxn3idLUgHTnc/sU5XZITZqEVfvcPP1ybmrVgpk35bhST38sUTpDSv3ULrQUajt1/hSe6CMh90OjYvvvUwrqCsGlA4OhBeksQVJJmBFYnfaN7okZN1CEIhZKNBuP91djHS/3eOwA66hx533loJZ9E5ZKOmz533AgmzC+uY32l+nd+BviO362Nly+9T0juvAhQ6s+540Fgy0IKNyw8653z8vVQqEko2Z/O2uONEsiH1wFG6upxRuQzUkv7NP+cVRoHB/7saUIugeDZ/N88J8tGEE7yu+LyWGxNtBy2L6igcDFpSItjaS/X9ceRm27YSp4JTF3sLEEFRASgFBFtS/iDHd9GvcMwFHPTX+dtopZe6VZGDqTUV4oGVV9pPjgKfqdVqbTZvp8Rqq+c6Y/QAiuLfe+vK25A2w1LaBrwK2h+rr2H2lkX69AEMl/Jc+TgptrdqAZO15GSlU0Fnu3EsEqVeM6tCNzygiZnZ/9wXPtWTuhBE0YoExJsf+5LKmw42XEa+LsleZYxp9EI9zeBN3doX7nW+5qLm9/m+R7Yo8eMDSABVEe1v+aml5vjdPhM5R6vGZmsx7aDjeRJDnjBI/ItlWQ2m8Q7WPvcQPmJQBt+OYg/Omzp6Hf0FO0oR+fsjYPsD4QzzocdkcX+PgYol9e6FiVuccaz7u4omn2JVc9kd/iU5Sv6dioyx5Cl5003YlDUGqhhKDaAot1f6UARjTmw+Y3dipEuJsDtYC5xKpx16cq2vUqLji7xHx2+TXKdTffHwmD6pKsz8sIvqSDdcQWXsIJ+RpjDRb/xwYYp0gbLp2fEdnYUQBC74v3fwFHFZjH7A4AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hide = 'lv10bo1';
export var propertyIconContainer = 'lv10bo4';
export var propertyNameContainer = 'lv10bo2';
export var propertyNameContent = 'lv10bo5';
export var propertyNameInnerContainer = 'lv10bo3';
export var propertyRoot = 'lv10bo0';
export var propertyValueContainer = 'lv10bo6';
export var section = 'lv10bo8';
export var sectionCollapsedIcon = 'lv10bod';
export var sectionContent = 'lv10boe';
export var sectionHeader = 'lv10bo9';
export var sectionHeaderIcon = 'lv10bob';
export var sectionHeaderName = 'lv10boc';
export var sectionHeaderTrigger = 'lv10boa';
export var tableButton = 'lv10bo7';